import React from "react";
import Seo from "../../components/Seo/seo";
import Layout from "../../components/Layout/layout";
import HeroSection from "../../components/KHFormComp/HeroSection/heroSection";
import KraftshalaHiringForm from "../../components/Forms/kraftshalaHiringForm";
import Overview from "../../components/KHMainComp/Overview/overview";
import Placements from "../../components/KHMainComp/Placements/placements";
import Trainers from "../../components/KHMainComp/Trainers/trainers";
import Curriculum from "../../components/KHMainComp/Curriculum/curriculum";
import Faq from "../../components/KHMainComp/FAQS/faq";
//import WhatsappWidget from "../../components/WhatsappWidget";

import {
  courseSnipptJSON,
  faqSnipptJSON,
  videoSnipptJSON,
  eventSnipptJSON,
} from "../../components/Seo/khSeoData";

import "./styles.scss";
import ContactUsPopup from "../../components/ContactUsPopup";

const KraftshalaHiring = (props) => {
  
  return (
    <React.Fragment>
      <Seo
        title="Hire Trained Digital Marketing Freshers from Us - Kraftshala"
        description="Best place to hire trained digital marketing professionals from. Freshers with hands-on experience in real-world industry practices. Hire from us now!"
        url="https://www.kraftshala.com/hire-from-us/"
        courseSnipptJSON={courseSnipptJSON}
        faqSnipptJSON={faqSnipptJSON}
        videoSnipptJSON={videoSnipptJSON}
        eventSnipptJSON={eventSnipptJSON}
      />
      <ContactUsPopup />

      <div className="main-hiring-page">
        <Layout>
          <div className="hero-div">
            <HeroSection urlParams={props.location.search} />
            <KraftshalaHiringForm search={props.location.search} />
          </div>
          <div id="overview" className="section">
            <Overview />
          </div>
          <div id="placements" className="section">
            <Placements />
          </div>
          <div id="trainers" className="section">
            <Trainers />
          </div>
          <div id="curriculum" className="section">
            <Curriculum />
          </div>
          <div id="faqs" className="section">
            <Faq />
          </div>
        </Layout>
      </div>
      {/* <WhatsappWidget link="https://wa.me/message/HHNAOZDIMB4ZE1" /> */}
    </React.Fragment>
  );
};

export default KraftshalaHiring;
